@charset "UTF-8";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.color_red{
  color: #FF0000;
}
main{
  padding-bottom: 200px;
}
#public_page button,
input{
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
input::placeholder{
  color:#B9B9B9
}
button{
  cursor: pointer;
}
a{
  text-decoration: none;
  color: #FFF;
}
#public_page .sp_txt{
  display: none;
}
/************************
ローディング
************************/
#loading_area{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.6);
  z-index: 1000;
}
#loading_area.hidden{
  display: none;
}
#loading_area .loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  top: calc(50% - 5em);
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}


/************************
管理者ページ/shop_admin/ #admins_page
************************/
#admins_page *{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
}
#admins_page .admin_btn_area{
  display: block;
  text-align: center;
  margin-top: 50px;
}
#admins_page .admin_btn_area button{
  color: #FFFFFF;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 50px;
  letter-spacing: 0.5em;
}

/************************
ヘッダー(管理画面)
************************/
#admins_page header{
  background-color: #00584D;
  color: #FFF;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 65px;
  box-sizing: border-box;
  z-index: 5;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 180px;
  top: 0;
}
#admins_page header .right_area{
  flex: 1;
  text-align: right;
}
#admins_page header .right_area span.name{
  margin-right: 10px;
}
#admins_page header .right_area .logout_btn{
  padding: 5px 30px;
  color: #FFFFFF;
  background-color: #00584D;
  border: 1px solid #FFFFFF;
}
#admins_page header .right_area .logout_btn:hover{
  padding: 5px 30px;
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #00584D;
}
#admins_page header .right_area .logout_btn span{
  padding-right: 20px;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center right;
}
#admins_page header .right_area .header_name_area{
  display: inline-block;
  margin-right: 10px;
}


/************************
サイドバー(管理画面)
************************/
#admins_page aside.sidebar{
  height: 100%;
  position: fixed;
  width: 15%;
  background-color: #F2F2F2;
  box-sizing: border-box;
  padding-top: 65px;
  padding-bottom: 30px;
  overflow-y: auto;
}
#admins_page aside.sidebar ul{
  list-style: none;
  padding: 0;
  margin: 0;
}
#admins_page aside.sidebar li{
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  padding: 20px;
  line-height: 2.5em;
  position: relative;
}
#admins_page aside.sidebar li.active,
#admins_page aside.sidebar li:hover{
  background-color: #669B94;
  color: #FFFFFF;
}



/************************
ログイン画面(管理画面)
************************/
#admins_page .subline{
  width: 80%;
  margin: 0 auto;
}
#admins_page .form_type_3{
  padding-top: 65px;
}
#admins_page .form_type_3 h2{
  text-align: center;
  font-weight: 400;
  margin: 65px 0;
}
#admins_page .form_type_3 .form_area{
  max-width: 1000px;
  margin: 0 auto;
  border: 1px solid #000000;
  padding: 50px 0;
  margin-bottom: 80px;
}
#admins_page .form_type_3 .form_area p{
  text-align: center;
}
#admins_page .form_type_3 .form_area form{
  margin-top: 80px;
  width: 60%;
  margin: 80px auto;
}
#admins_page .form_type_3 .form_area form dl{
  margin-bottom: 50px;
}
#admins_page .form_type_3 .form_area form dd{
  margin-left: 0;
}
#admins_page .form_type_3 .form_area form input{
  width: 100%;
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #CCCCCC;
}
#admins_page .form_type_3 .form_area p.password_application_btn{
  text-decoration: underline;
  cursor: pointer;
}
#admins_page .form_type_3 .button_area{
  text-align: center;
  margin-top: 80px;
}
#admins_page .form_type_3 .button_area button{
  width: 150px;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 10px;
  border: none;
  margin: 0 20px;
}
#admins_page .form_type_3 .button_area button.desabled{
  background-color: #CCCCCC;
  pointer-events: none;
}


/************************
メインコンテンツ(管理画面)
************************/
#admins_page main{
  position: absolute;
  left: 15%;
  top: 65px;
  width: 85%;
  padding: 20px 50px 50px;
}
#admins_page main h2.page_title{
  text-align: center;
  margin-bottom: 80px;
}
#admins_page main h2 span{
  border-bottom: 1px solid #00584D;
}
#admins_page .breadcrumb{
  margin: 10px 0;
}
#admins_page main .subline{
  width: 90%;
  display: block;
  margin: 0 auto;
}

/************************
ページング
************************/
.paging_area{
  text-align: right;
  margin: 20px 0;
}
.paging_area button{
  margin: 0 2px;
  border-radius: 0;
  padding: 7px 9px;
  border: 1px solid #969696;
  background-color: #FFFFFF;
  vertical-align: middle;
}
.paging_area button.active{
  border: 1px solid #969696;
  background-color: #969696;
  color: #FFFFFF;
  cursor: inherit;
}
.paging_area button.pre_btn,
.paging_area button.next_btn{
  padding: 7px;
}

/************************
ホーム /admin/home
************************/
#admins_page #home_page .situation_area{
  display: inline-block;
  width: calc((100% / 2) - 60px);
  min-width: 300px;
  margin: 0 30px;
}
#admins_page #home_page .situation_area ul{
  list-style: none;
  border: 1px solid #808080;
  padding: 40px 30px;
}
#admins_page #home_page .situation_area ul li{
  border-bottom: 1px dotted #808080;
  line-height: 3.5em;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#admins_page #home_page .situation_area ul li:last-child{
  border-bottom: none;
}

/************************
管理画面共通
************************/
#admins_page .box_type_1{
  margin-bottom: 50px;
}
#admins_page .box_type_1 .title_area{
  border: 1px solid #8C8C8C;
  padding: 10px 30px;
  background-color: #E6E6E6;
}
#admins_page .box_type_1 .title_area h3{
  border: none;
  margin: 0;
}
#admins_page .box_type_1 .content_area{
  border: 1px solid #CCCCCC;
}
#admins_page .search_area.box_type_1 .content_area{
  padding: 15px 30px;
}
#admins_page .box_type_1 .content_area dl{
  display: flex;
}
#admins_page .box_type_1 .content_area dl dt{
  background-color: #CCCCCC;
  padding: 8px 15px;
}
#admins_page .box_type_1 .content_area dl dd{
  margin-left: 10px;
}
#admins_page .box_type_1 .content_area dl dd.right{
  margin-left: auto;
}
#admins_page .box_type_1 .content_area dl dd input{
  padding: 10px;
  width: 35vw;
  border: 1px solid #CBCBCB;
}
#admins_page .box_type_1 .content_area dl dd button{
  padding: 10px 50px;
  letter-spacing: 5px;
  color: #FFFFFF;
  background-color: #373239;
  border: none;
  cursor: pointer;
}
#admins_page .box_type_1 .content_area table{
  width: 100%;
  border-collapse: collapse;
}
#admins_page .box_type_1 .content_area table th{
  width: 12em;
  text-align: left;
  vertical-align: middle;
}
#admins_page .box_type_1 .content_area table td.flex{
  display: flex;
  align-items: center;
}
#admins_page .box_type_1 .content_area table td.birthday_box span{
  margin-left: 20px;
}
#admins_page .box_type_1 .content_area table td.mail_magazine_flag_box label:first-child input[type='radio']{
  display: inline-block;
  margin-bottom: 20px;
}
#admins_page .box_type_1 .content_area table td.flex span.header{
  width: 80px;
  text-align: right;
}
#admins_page .box_type_1 .content_area table td input[type='text']{
  width: calc(100% - 50px);
  max-width: 450px;
  padding: 6px;
  border: 1px solid #CBCBCB;
}
#admins_page .box_type_1 .content_area table td input[name='re_password']{
  margin-top: 30px;
}
#admins_page .box_type_1 .content_area table td input[name='family_name'],
#admins_page .box_type_1 .content_area table td input[name='first_name'],
#admins_page .box_type_1 .content_area table td input[name='family_name_furigana'],
#admins_page .box_type_1 .content_area table td input[name='first_name_furigana']{
  margin-left: 10px;
  margin-right: 30px;
}
#admins_page .box_type_1 .content_area table td select{
  width: 100%;
  max-width: 200px;
  padding: 5px;
  border: 1px solid #CBCBCB;
}
#admins_page .box_type_1 .content_area table td button{
  vertical-align: top;
  margin-right: 20px;
}
#admins_page .box_type_1 .content_area table td button.file_btn{
  border: none;
  padding: 0;
}
#admins_page .box_type_1 .content_area table td button.file_btn label{
  padding: 7px 25px;
  display: block;
  cursor: pointer;
}
#admins_page .box_type_1 .content_area table td textarea{
  width: 100%;
  max-width: 450px;
  resize: none;
  padding: 5px;
  border: 1px solid #767676;
}
#admins_page .box_type_1 .content_area table td .radio_btn_label{
  margin-right: 40px;
}
#admins_page .box_type_1 .content_area table td.td_flex{
  display: flex;
}
#admins_page .box_type_1 .content_area table td .yen_icon_area{
  width: 40px;
  display: inline-block;
  text-align: center;
  border: 1px solid #767676;
  padding: 2.5px 0;
  background-color: #F2F2F2;
  border-right: none;
}
#admins_page .box_type_1 .button_area,
#admins_page .box_type_2 .button_area{
  margin-top: 50px;
  text-align: center;
}
#admins_page .box_type_1 .button_area button,
#admins_page .box_type_2 .button_area button{
  width: 150px;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 10px;
  border: none;
  margin: 0 20px;
}

#admins_page .box_type_2 .button_area #save_btn{
  background-color: #767676;
}
#admins_page .box_type_2 .button_area #save_btn.desabled{
  background-color: #CCCCCC;
  pointer-events: none;
}
#admins_page .box_type_1 .button_area button.desabled,
#admins_page .box_type_2 .button_area button.desabled{
  background-color: #CCCCCC;
  pointer-events: none;
}
#admins_page .box_type_1 .progress_show,
#admins_page .box_type_2 .progress_show{
  display: none;
}
#admins_page .box_type_1 .progress .progress_show,
#admins_page .box_type_2 .progress .progress_show{
  display: inline;
}
#admins_page .box_type_1 .progress .progress_none,
#admins_page .box_type_2 .progress .progress_none{
  display: none;
}
#admins_page .box_type_1 .thumbnail_area,
#admins_page .box_type_2 .thumbnail_area{
  display: inline-block;
  position: relative;
}
#admins_page .box_type_1 .thumbnail_area img,
#admins_page .box_type_2 .thumbnail_area img{
  width: 200px;
  height: auto;
  min-width: 100px;
  min-height: 100px;
  border: 1px dotted #E3E3E3;
}
#admins_page .box_type_1 .thumbnail_area .image_delete,
#admins_page .box_type_2 .thumbnail_area .image_delete{
  margin: 0;
  border: 1px solid #CCCCCC;
  border-radius: 100%;
  position: absolute;
  right: -30px;
  top: -7px;
  width: 26px !important;
  height: 26px;
}
#admins_page .box_type_1 .thumbnail_area .image_delete span,
#admins_page .box_type_2 .thumbnail_area .image_delete span{
  font-size: 16px;
  position: absolute;
  top: -1px;
  left: 7px;
  pointer-events: none;
}
#admins_page .box_type_2 dd button.file_btn{
  border: none;
  padding: 0;
  vertical-align: top;
  margin-right: 20px;
}
#admins_page .box_type_2 dd button.file_btn label{
  padding: 7px 25px;
  display: block;
  cursor: pointer;
}
#admins_page #setting_page .box_type_1 .content_area{
  padding: 0;
}
#admins_page .box_type_1 form,
#admins_page .box_type_2 form{
  width: 100%;
}
#admins_page .box_type_1 th{
  background-color: #EDEAD3;
  padding: 20px 30px;
  border-bottom: 1px solid #8C8C8C;
}
#admins_page .box_type_1 td{
  padding: 20px 30px;
  border-bottom: 1px solid #8C8C8C;
}
#admins_page .box_type_1 tr:last-child th,
#admins_page .box_type_1 tr:last-child td{
  border-bottom: none;
}
#admins_page #setting_page .box_type_1 td input.tax_input{
  width: 50px;
  margin-right: 10px;
}
#admins_page .box_type_2 dl{
  margin-bottom: 60px;
}
#admins_page .box_type_2 dt{
  margin: 20px 0;
}
#admins_page .box_type_2 dd input[type='text']{
  width: 100%;
  padding: 5px;
  border: 1px solid #BCBCBC;
}
#admins_page .box_type_2 dd input[name='publication_date']{
  width: 200px;
  padding: 5px;
  margin-right: 30px;
}
#admins_page .box_type_2 dd select{
  width: 70px;
  padding: 5px;
  border: 1px solid #BCBCBC;
}
#admins_page .box_type_2 dd .mailing_list_area{
  margin-top: 10px;
}
#admins_page .box_type_2 dd .delete_btn{
  color: #FF0000;
  border-color: #FF0000;
  display: block;
  margin: auto;
  background-color: #FFFFFF;
  padding: 3px 10px;
  border-style: solid;
  margin-left: 40px;
}
#admins_page .box_type_2 dd .radio_btn_label{
  margin-right: 40px;
}
#admins_page .box_type_2 dd button.add_btn{
  padding: 8px 30px;
  color: #FFFFFF;
  background-color: #00584D;
  border: none;
  cursor: pointer;
}
#admins_page .box_type_2 dd textarea{
  padding: 5px;
  width: 100%;
  resize: none;
}
#admins_page .sort_menu_area{
  display: flex;
  margin-bottom: 20px;
}
#admins_page .sort_menu_area .left_group{
  display: flex;
}
#admins_page .sort_menu_area .left_group select{
  padding: 10px 5px;
  margin-right: 10px;
  border: 1px solid #CBCBCB;
}
#admins_page .sort_menu_area .left_group button{
  padding: 10px 25px;
  letter-spacing: 5px;
  color: #FFFFFF;
  background-color: #373239;
  border: none;
  cursor: pointer;
}
#admins_page .sort_menu_area .right_group{
  margin-left: auto;
}
#admins_page .sort_menu_area .right_group button{
  padding: 10px 30px 10px 50px;
  color: #FFFFFF;
  letter-spacing: 2px;
  background-color: #00584D;
  border: none;
  cursor: pointer;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 23px center;
}
#admins_page .list_area table{
  width: 100%;
  border-collapse: collapse;
}
#admins_page .list_area table th{
  background-color: #EDEAD3;
  border: 1px solid #8C8C8C;
  padding: 10px 0;
}
#admins_page .list_area table td{
  border: 1px solid #8C8C8C;
  padding: 10px 5px;
}
#admins_page .list_area table td.checkbox_box{
  text-align: center;
  width: 50px;
}
#admins_page .list_area table td.checkbox_box input{
  margin: 0;
}
#admins_page .list_area table td.image_box{
  text-align: center;
}
#admins_page .list_area table td.image_box .thumbnail_area{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
#admins_page .list_area table td.category_box{
  text-align: center;
}
#admins_page .list_area table td.price_box{
  text-align: right;
}
#admins_page .list_area table td.stock_quantity_box{
  text-align: right;
}
#admins_page .list_area table td.department_box{
  text-align: center;
}
#admins_page .list_area table td.status_box{
  text-align: center;
}
#admins_page .list_area table td.datetime_box{
  text-align: center;
}
#admins_page .list_area table td.button_box{
  text-align: center;
  width: 100px;
}
#admins_page .list_area table td.image_box div{
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 1px solid #AAAAAA;
}
#admins_page .list_area table td.button_box button{
  display: block;
  margin: auto;
  background-color: #FFFFFF;
  padding: 3px 10px;
  border-style: solid;
}
#admins_page .list_area table td.button_box button:first-child{
  margin-bottom: 10px;
}
#admins_page .list_area table td.button_box button.delete_btn{
  color: #FF0000;
  border-color: #FF0000;
}
#admins_page #mailing_list_edit_page .list_area table td.button_box button.delete_btn{
  margin-bottom: 0;
}
#admins_page .list_area table td.button_box button.add_btn{
  margin-bottom: 0;
}
#admins_page .list_area table td.name_box span.margin_right_10px{
  margin-right: 10px;
}
#admins_page .under_menu_area{
  display: flex;
  margin-top: 30px;
}
#admins_page .under_menu_area .left_group{
  display: flex;
}
#admins_page .under_menu_area .left_group select {
  padding: 10px 5px;
  margin-right: 10px;
  border: 1px solid #CBCBCB;
}
#admins_page .under_menu_area .left_group button {
  padding: 10px 25px;
  letter-spacing: 5px;
  color: #FFFFFF;
  background-color: #373239;
  border: none;
  cursor: pointer;
}
#admins_page .under_menu_area .left_group button.add_btn {
  padding: 10px 30px 10px 50px;
  color: #FFFFFF;
  letter-spacing: 2px;
  background-color: #00584D;
  border: none;
  cursor: pointer;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 23px center;
}
#admins_page .under_menu_area .right_group{
  margin-left: auto;
}
#admins_page .under_menu_area .right_group .paging_area{
  margin: 0;
}
#admins_page .under_menu_area .right_group .paging_area button{
  border: none;
  margin-left: 10px;
}
#admins_page .under_menu_area .right_group .paging_area .active{
  background-color: #00584D;
}
#admins_page .under_menu_area .right_group .paging_area button:hover{
  background-color: #00584D;
  color: #FFFFFF;
}
#admins_page .under_menu_area .right_group .paging_area .pre_btn,
#admins_page .under_menu_area .right_group .paging_area .next_btn{
  padding-left: 20px;
  padding-right: 20px;
  background-color: #00584D;
  color: #FFFFFF;
}
/* add 20211116 住所自動入力ボタン */
#admins_page #add_form td button,
#admins_page #update_form td button{
  width: 150px;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 5px 12px;
  border: none;
  margin: 0 20px;
  border-radius: 2px;
}

/************************
商品一覧(管理画面)
************************/
#admins_page .title_content_box{
  margin-bottom: 30px;
}
#admins_page .title_content_box h2{
  margin: 0;
  padding: 10px 20px;
  background-color: #E2E2E2;
}
#admins_page .title_content_box .title_box{
  margin: 0;
  padding: 10px 20px;
}
#admins_page .search_form_area.title_content_box .title_box{
  background-color: #E2E2E2;
  border: none;
}
#admins_page .search_form_area.title_content_box .content_box{
  border: #E2E2E2 1px solid;
  padding: 30px 50px;
}
#admins_page .title_content_box .content_box form{
  width: 100%;
}
#admins_page .title_content_box .content_box form .search_box{
  display: inline-block;
  margin-right: 30px;
}
#admins_page .title_content_box .content_box form .search_box dl{
  display: flex;
  align-items: center;
}
#admins_page .title_content_box .content_box form .search_box dt{
  width: 100px;
  background-color: #E2E2E2;
  text-align: center;
  padding: 10px;
}
#admins_page .title_content_box .content_box form .search_box .mailaddress_input dt{
  width: 150px;
}
#admins_page .title_content_box .content_box form .search_box dd{
  margin-left: 10px;
}
#admins_page .title_content_box .content_box form .search_box dd select{
  padding: 11px;
  font-size: 16px;
}
#admins_page .title_content_box .content_box form .search_btn_area{
  text-align: center;
  margin-top: 30px;
}
#admins_page .title_content_box .content_box form .search_btn_area button{
  color: #FFFFFF;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 50px;
  letter-spacing: 0.5em;
}
#admins_page .sort_add_area{
  margin-bottom: 10px;
  display: flex;
}
#admins_page .sort_add_area #sort_select{
  padding: 0 5px;
}
#admins_page .sort_add_area .sort_btn{
  background-color: #373239;
  border: none;
  color: #FFFFFF;
  letter-spacing: 0.2em;
  padding: 8px 15px;
  margin-left: 20px;
}
#admins_page .sort_add_area .add_btn{
  background-color: #E5767C;
  border: none;
  color: #FFFFFF;
  padding: 8px 15px;
  margin-left: auto;
}
#admins_page .sort_add_area .add_btn span{
  background-color: #FFFFFF;
  color: #E5767C;
  border-radius: 50%;
  padding: 1px 5px;
  margin-right: 7px;
}
#admins_page table.list_table{
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
#admins_page table.list_table th,
#admins_page table.list_table td{
  border: 1px solid #373239;
}
#admins_page table.list_table th{
  background-color: #E0EAF5;
  padding: 20px 0;
}
#admins_page table.list_table td .thumbnail_area{
  width: 120px;
  height: 90px;
  background-color: #E6E6E6;
  margin: 10px auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
#admins_page table.list_table th.thumbnail_item{
  width: 160px;
}
#admins_page table.list_table th.state_item{
  width: 100px;
}
#admins_page table.list_table th.edit_delete_item{
  width: 100px;
}
#admins_page table.list_table td.item_name_item{
  text-align: left;
  padding-left: 10px;
}
#admins_page table.list_table td button{
  background-color: inherit;
  border: none;
  display: block;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.2em;
  margin: 0 auto;
  padding: 5px 15px;
}
#admins_page table.list_table td button:hover{
  background-color: #E2E2E2;
}
#admins_page table.list_table td .delete_btn{
  color: #FF0000;
}
#admins_page #item_list_page .list_area table th:nth-child(4),
#admins_page #item_list_page .list_area table th:nth-child(5),
#admins_page #item_list_page .list_area table th:nth-child(8){
  width: 80px;
}
#admins_page #item_list_page .list_area table th:nth-child(6),
#admins_page #item_list_page .list_area table th:nth-child(7){
  width: 60px;
}
#admins_page #item_list_page .list_area table td:nth-child(4),
#admins_page #item_list_page .list_area table td:nth-child(5),
#admins_page #item_list_page .list_area table td:nth-child(6),
#admins_page #item_list_page .list_area table td:nth-child(7){
  text-align: center;
}

/************************
商品情報追加(管理画面)
************************/
#admins_page .title_content_box .title_box{
  background-color: #E0EAF5;
  border: 1px solid #373239;
}
#admins_page .title_content_box .content_box{
  padding: 15px;
  border: 1px solid #E2E2E2;
}
#admins_page .title_content_box .content_box form{
  width: 100%;
}
#admins_page .order_detail_page .title_content_box .content_box td form{
  width: initial;
  display: inline-block;
}
#admins_page .title_content_box .content_box table{
  width: 100%;
}
#admins_page .title_content_box .content_box table th{
  width: 180px;
  text-align: left;
  margin-bottom: 10px;
}
#admins_page .title_content_box .content_box table th span.primary{
  font-size: 12px;
  color: #FF0000;
  margin-left: 8px;
}
#admins_page .title_content_box .content_box table td{
  padding: 10px 0;
}
#admins_page .title_content_box .content_box table td input[type="text"],
#admins_page .title_content_box .content_box table td input[type="number"]{
  background-color: inherit !important;
  border: 1px solid #E2E2E2 !important;
  border-radius: 0 !important;
  padding: 5px 10px !important;
}
#admins_page .title_content_box .content_box table td input[type="text"]{
  width: 400px;
  box-sizing: border-box;
}
#admins_page .title_content_box .content_box table td input[type="checkbox"]{
  margin-right: 5px;
  margin-left: 20px;
}
#admins_page .title_content_box .content_box table td label:first-child input[type="checkbox"]{
  margin-left: 0px;
}
#admins_page .title_content_box .content_box table td.radio_area label{
  margin-right: 20px;
}
#admins_page .title_content_box .content_box table td select{
  width: 400px;
  padding: 10px;
  font-size: 16px;
  box-sizing: content-box;
  border: 1px solid #E2E2E2;
}
#admins_page .order_detail_page .title_content_box .content_box table td select{
  vertical-align: middle;
}
#admins_page .title_content_box .content_box table td input[type="number"]{
  width: 400px;
}
#admins_page .title_content_box .content_box table td .yen{
  margin-left: 10px;
  vertical-align: bottom;
}
#admins_page .title_content_box .content_box table td #thmbnail_img{
  min-width: 200px;
  min-height: 70px;
  border: 1px dashed #E2E2E2;
  vertical-align: bottom;
  margin-right: 20px;
}
#admins_page .title_content_box .content_box table td textarea{
  width: 100%;
  width: 100%;
  box-sizing: border-box;
  height: 10em;
  font-size: 16px;
  padding: 10px;
  resize: none;
  border: 1px solid #E2E2E2;
}
#admins_page .title_content_box .input_btn_area{
  margin: 50px auto;
  text-align: center;
}
#admins_page .title_content_box .input_btn_area button{
  color: #FFFFFF;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 50px;
  letter-spacing: 0.5em;
}
#admins_page #item_edit_page #add_form td button,
#admins_page #item_edit_page #update_form td button{
  width: auto;
}

/************************
商品情報編集(管理画面)
************************/
#admins_page .title_content_box .content_box form .update_item_btn_area{
  margin: 50px auto;
  text-align: center;
}
#admins_page .title_content_box .content_box form .update_item_btn_area button{
  color: #FFFFFF;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 50px;
  letter-spacing: 0.5em;
}

/************************
会員情報一覧
************************/
#admins_page .title_content_box .content_box form .search_box dd input{
  background-color: inherit;
}
#admins_page .member_list_table .sort_add_area{
  margin-bottom: 10px;
  display: flex;
}
#admins_page .member_list_table .sort_add_area .add_member_btn{
  background-color: #E5767C;
  border: none;
  color: #FFFFFF;
  padding: 8px 15px;
  margin-left: auto;
}
#admins_page .member_list_table .sort_add_area .add_member_btn span{
  background-color: #FFFFFF;
  color: #E5767C;
  border-radius: 50%;
  padding: 1px 5px;
  margin-right: 7px;
}
#admins_page .member_list_table table{
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
#admins_page .member_list_table table th,
#admins_page .member_list_table table td{
  border: 1px solid #373239;
}
#admins_page .member_list_table table .withdrawal th,
#admins_page .member_list_table table .withdrawal td{
  background-color: #969696;
}
#admins_page .member_list_table table th{
  background-color: #E0EAF5;
  padding: 20px 0;
}
#admins_page .member_list_table table th.address_1_item{
  width: 90px;
}
#admins_page .member_list_table table th.output_item{
  width: 100px;
}
#admins_page .member_list_table table th.edit_delete_item{
  width: 100px;
}
#admins_page .member_list_table table td{
  padding: 10px 0;
}
#admins_page .member_list_table table td button {
  background-color: inherit;
  border: none;
  display: block;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.2em;
  margin: 0 auto;
  padding: 5px 15px;
}
#admins_page .member_list_table table td button:hover {
  background-color: #E2E2E2;
}
#admins_page .member_list_table table td button.output_btn{
  color: #FFFFFF;
  background-color: #373239;
}
#admins_page .member_list_table table td button.delete_btn{
  color: #FF0000;
}


/************************
会員新規登録
会員編集
************************/
#admins_page .infomation_table_section .content_box{
  padding: 0;
  border: 1px solid #969696;
  border-top: none;
  border-bottom: none;
}
#admins_page .infomation_table_section .content_box table{
  border-collapse: collapse;
}
#admins_page .infomation_table_section .content_box table th{
  background-color: #E6E6E6;
  padding-left: 20px;
  width: 180px;
}
#admins_page .infomation_table_section .content_box table td{
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
#admins_page .infomation_table_section .content_box table td:after{
  content: '';
  width: 95%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px dashed #B1B1B1;
}
#admins_page .infomation_table_section .content_box table tr.last_child td:after{
  content: '';
  border-bottom: none;
}
#admins_page .infomation_table_section .content_box table th{
  border-bottom: 1px solid #969696;
}
#admins_page .infomation_table_section .content_box table th span{
  font-size: 12px;
  color: #FF0000;
  margin-left: 10px;
}
#admins_page .infomation_table_section .content_box table tr.last_child td{
  border-bottom: 1px solid #969696;
}
#admins_page .infomation_table_section .content_box table td span{
  width: 50px;
  display: inline-block;
}
#admins_page .infomation_table_section .content_box table td.name_en span{
  width: 130px;
  display: inline-block;
}
#admins_page .infomation_table_section .content_box table td input{
  margin-right: 40px;
}
#admins_page .infomation_table_section .content_box table td.postal_code_input_area span{
  width: 30px;
  display: inline-block;
}
#admins_page .infomation_table_section .content_box table td.postal_code_input_area button{
  color: #FFFFFF;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 20px;
  letter-spacing: 0.2em;
  border: none;
}
#admins_page .infomation_table_section .content_box table td.address_input_area span{
  width: 150px;
  display: inline-block;
}
#admins_page .infomation_table_section .add_member_btn_area{
  text-align: center;
  margin-top: 50px;
}
#admins_page .order_detail_page .infomation_table_section .add_member_btn_area{
  display: inline-block;
  margin-top: 0;
  margin-left: 40px;
}
#admins_page .infomation_table_section .add_member_btn_area button{
  color: #FFFFFF;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 50px;
  letter-spacing: 0.5em;
}
#admins_page .order_detail_page .infomation_table_section .black_btn_area{
  display: inline-block;
  margin-top: 0;
  margin-left: 40px;
}
#admins_page .infomation_table_section .black_btn_area button{
  color: #FFFFFF;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 50px;
  letter-spacing: 0.5em;
}

/************************
管理者一覧
************************/
#admins_page .admin_list_table .sort_add_area{
  margin-bottom: 10px;
  display: flex;
}
#admins_page .admin_list_table .sort_add_area .add_admin_btn{
  background-color: #E5767C;
  border: none;
  color: #FFFFFF;
  padding: 8px 15px;
  margin-left: auto;
}
#admins_page .admin_list_table .sort_add_area .add_admin_btn span{
  background-color: #FFFFFF;
  color: #E5767C;
  border-radius: 50%;
  padding: 1px 5px;
  margin-right: 7px;
}
#admins_page .admin_list_table td{
  padding: 10px 0;
}
#admins_page .admin_list_table td .delete_btn{
  color: #FF0000;
}
#admins_page table.list_table td.edit_delete_area{
  width: 100px;
}

/************************
お知らせ一覧
************************/
#admins_page table.list_table td{
  padding: 10px;
}
#admins_page table.list_table td.checkbox_area{
  width: 40px;
}
#admins_page table.list_table td.title_area{
  text-align: left;
}
#admins_page table.list_table td.insert_datetime_area{
  width: 200px;
}
#admins_page table.list_table td.status_area{
  width: 100px;
}

/************************
お知らせ詳細
************************/


/************************
CKエディタ
************************/
.ck-editor__editable_inline {
  min-height: 400px;
}

/************************
受注・配送一覧画面 add 20211117
************************/
#admins_page #order_list_page .list_area table th:nth-child(1){
  width: 100px;
}
#admins_page #order_list_page .list_area table th:nth-child(2),
#admins_page #order_list_page .list_area table th:nth-child(7),
#admins_page #order_list_page .list_area table th:nth-child(8){
  width: 95px;
}
#admins_page #order_list_page .list_area table th:nth-child(6){
  width: 80px;
}
#admins_page #order_list_page .list_area table td:nth-child(2),
#admins_page #order_list_page .list_area table td:nth-child(6),
#admins_page #order_list_page .list_area table td:nth-child(7),
#admins_page #order_list_page .list_area table td:nth-child(8){
  text-align: center;
}
#admins_page #order_list_page .list_area table td:nth-child(8) button{
  border: none;
  background-color: transparent;
  color: #00584D;
  font-size: 1em;
}
/************************
メールマガジン一覧画面 add 20211129
************************/
#admins_page #member_list_page .list_area table th:nth-child(2){
  width: 15%;
}








/************************
一般ページ/　#public_page
************************/
/************************
共通部分
************************/
#public_page{
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #000000;
  font-weight: 500;
}
#public_page .subline_1200{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
#public_page .subline_1000{
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
#public_page .subline_500{
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
#public_page .breadcrumb a{
  color: inherit;
  text-decoration: none;
}
#public_page .breadcrumb span{
  margin-right: 10px;
  cursor: pointer;
  text-decoration: underline;
}
#public_page .breadcrumb span.nolink{
  cursor: inherit;
  text-decoration: none;
}
#public_page .flex_1{
  flex: 1;
}

/************************
ページング
************************/
#public_page .paging_area{
  margin: 0;
}
#public_page .paging_area button{
  border: none;
  margin-left: 10px;
}
#public_page .paging_area .active{
  background-color: #00584D;
}
#public_page .paging_area button:hover{
  background-color: #00584D;
  color: #FFFFFF;
}
#public_page .paging_area .pre_btn,
#public_page .paging_area .next_btn{
  padding-left: 20px;
  padding-right: 20px;
  background-color: #00584D;
  color: #FFFFFF;
}

/************************
ヘッダー
************************/
#public_page header {
  background-color: #00584D;
  color: #FFF;
  padding: 20px 20px;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 101;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 80px;
  height: 94px;
}
#public_page header .header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#public_page header .left_area .menu_toggle_btn{
  position: relative;
  height: 30px;
  width: 30px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
}
#public_page header .left_area .menu_toggle_btn span{
  position: absolute;
  left: 0;
  height: 3px;
  width: 30px;
  background-color: #FFFFFF;
  border-radius: 2px;
  display: inline-block;
  box-sizing: border-box;
}
#public_page header .left_area .menu_toggle_btn span:nth-of-type(1){
  top: 2px;
}
#public_page header .left_area .menu_toggle_btn span:nth-of-type(2){
  top: 12px;
}
#public_page header .left_area .menu_toggle_btn span:nth-of-type(3){
  top: 22px;
}
#public_page header .right_area{
  margin-left: auto;
}
#public_page header .right_area button{
  border: none;
  background-color: transparent;
  color: #FFFFFF;
  margin: 0 2px;
  text-align: center;
  position: relative;
}
#public_page header .right_area button .badge{
  position: absolute;
  background-color: #A14213;
  display: inline-block;
  width: 26px;
  height: 24px;
  border-radius: 26px;
  padding-top: 2px;
  top: -10px;
  right: 5px;
}
#public_page header .right_area button img{
  height: 25px;
}
#public_page header .subline_1200{
  position: relative;
}
#public_page header .login_btn_area{
  position: absolute;
  bottom: -90px;
  right: 0;
}
#public_page .login_btn_area button{
  border: none;
  padding: 10px 15px;
  color: #FFFFFF;
}
#public_page .login_btn_area button:nth-of-type(1){
  margin-right: 20px;
  background: linear-gradient(#00584D, #002319);
}
#public_page .login_btn_area button:nth-of-type(2){
  padding: 10px 20px;
  background: linear-gradient(#A69477, #644614);
}
#public_page header .member_menu_btn_area{
  position: absolute;
  bottom: -90px;
  right: 0;
}
#public_page .member_menu_btn_area button{
  border: none;
  padding: 10px 15px;
  color: #FFFFFF;
}
#public_page .member_menu_btn_area button.member_menu_btn{
  padding: 10px 30px 10px 15px;
  background: linear-gradient(#A69477, #644614);
  position: relative;
}
#public_page .member_menu_btn_area button.member_menu_btn .arrow{
  position: absolute;
  display: inline-block;
  right: 7px;
  top: calc(50% - 7px);
  width: 7px;
  height: 7px;
  border-bottom: 2px solid #FFFFFF;
  border-right: 2px solid #FFFFFF;
  transform: rotate(45deg);
}
#public_page .member_menu_btn_area .submenu_area{
  position: absolute;
  color: #000000;
  right: 0;
  top: 110%;
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s;
}
#public_page .member_menu_btn_area .submenu_area.active{
  max-height: 100vh;
  transition: max-height 1s;
}
#public_page .member_menu_btn_area .submenu_area ul{
  list-style: none;
  padding: 0;
  text-align: center;
  border: 1px solid #A69477;
  width: 11em;
  position: relative;
  background-color: #FFFFFF;
}
#public_page .member_menu_btn_area .submenu_area ul::before,
#public_page .member_menu_btn_area .submenu_area ul::after{
  content: "";
  position: absolute;
}
#public_page .member_menu_btn_area .submenu_area ul::before{
  top: -10px;
  right: 10px;
  border-style: solid;
  border-color: transparent transparent #A69477 transparent;
  border-width: 0 10px 10px 10px;
}
#public_page .member_menu_btn_area .submenu_area ul::after{
  top: -9px;
  right: 11px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  border-width: 0 9px 9px 9px;
  z-index: 10;
}
#public_page .member_menu_btn_area .submenu_area ul li{
  padding: 20px 0;
  cursor: pointer;
  border-bottom: 1px solid #DBD4C9;
}
#public_page .member_menu_btn_area .submenu_area ul li:last-child{
  border-bottom: none;
}
#public_page .member_menu_btn_area .submenu_area ul li:hover{
  background-color: #DBD4C9;
}

/************************
サイドメニュー
************************/
#public_page #sidemenu_area{
  display: block;
  width: 400px;
  height: 100%;
  position: fixed;
  left: -400px;
  background-color: rgba(16,165,146,.9);
  z-index: 101;
  transition: left 0.5s;
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center bottom 40px;
}
#public_page #sidemenu_area.active{
  left: 0;
}
#public_page #sidemenu_area .close_btn{
  position: absolute;
  top: 20px;
  left: 20px;
  height: 20px;
  width: 30px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
}
#public_page #sidemenu_area .close_btn span{
  position: absolute;
  left: 0;
  height: 3px;
  width: 30px;
  background-color: #FFFFFF;
  border-radius: 2px;
  display: inline-block;
  box-sizing: border-box;
  top: 15px;
}
#public_page #sidemenu_area .close_btn span:nth-of-type(1){
  transform: rotate(45deg);
}
#public_page #sidemenu_area .close_btn span:nth-of-type(2){
  transform: rotate(-45deg);
}
#public_page #sidemenu_area .menu_area{
  margin-top: 3vw;
  margin-bottom: 3vw;
}
#public_page #sidemenu_area .menu_area ul{
  color: #FFFFFF;
  list-style: none;
  padding: 0;
}
#public_page #sidemenu_area .menu_area ul li{
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #FFFFFF;
  cursor: pointer;
}
#public_page #sidemenu_area .menu_area ul li:hover{
  color: #10A592;
  background-color: #A8E6DE;
}
#public_page #sidemenu_area .menu_area ul li:last-child{
  border-bottom: none;
}
#public_page #sidemenu_area .search_login_btn_area{
  text-align: center;
  width: 70%;
  margin: auto;
}
#public_page #sidemenu_area .search_area input[name='search_name']{
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  background-size: 2em;
  background-repeat: no-repeat;
  background-position: right 10px center;
}
#public_page #sidemenu_area .search_area button{
  border: none;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 5px 20px;
  width: 100%;
  margin-top: 10px;
}
#public_page #sidemenu_area .login_btn_area{
  margin-top: 30px;
}
#public_page #sidemenu_area .login_btn_area button{
  padding: 10px 15px;
  width: 9em;
}
#public_page #sidemenu_area .logout_btn_area{
  margin-top: 30px;
}
#public_page #sidemenu_area .logout_btn_area button{
  padding: 10px 15px;
  width: 9em;
}
#public_page #sidemenu_area .logout_btn_area button.logout_btn{
  padding: 10px 20px;
  border: none;
  color: #FFFFFF;
  background: linear-gradient(#A69477, #644614);
}

/************************
トップページ
************************/
#public_page .slick-slider{
  font-size: 0;
  overflow-x: hidden;
  padding-top: 94px;
}
#public_page .slider_item{
  height: 410px;
  background-size: cover;
}
#public_page article{
  margin-top: 60px;
}
#public_page article .col_2_wrap{
  display: flex;
}
#public_page article .col_2_wrap aside.sidebar{
  width: 200px;
  padding-bottom: 120px;
}
#public_page article .col_2_wrap aside.sidebar .search_area{
  margin-bottom: 60px;
}
#public_page article .col_2_wrap aside.sidebar input[name='search_name'] {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  background-size: 2em;
  background-repeat: no-repeat;
  background-position: right 10px center;
}
#public_page article .col_2_wrap aside.sidebar .search_area button{
  border: none;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 5px 20px;
  width: 100%;
  margin-top: 10px;
}
#public_page article .col_2_wrap aside.sidebar h2{
  font-size: 16px;
  border-bottom: 1px dashed #00584D;
}
#public_page article .col_2_wrap aside.sidebar ul{
  list-style: none;
  padding: 0 5px;
}
#public_page article .col_2_wrap aside.sidebar ul li{
  border: 5px solid #00584D;
  margin: 10px 0;
  padding: 20px 15px;
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: right 30px center;
  cursor: pointer;
}
#public_page article .col_2_wrap aside.sidebar ul li span{
  font-size: 18px;
  position: relative;
}
#public_page article .col_2_wrap aside.sidebar ul li span::after{
  content: '';
  position: absolute;
  width: calc(100% + 10px);
  border-bottom: 10px solid rgba(153,188,184,.9);
  bottom: 0;
  left: -5px;
  z-index: -1;
}
#public_page article .col_2_wrap main{
  flex: 1;
  padding-left: 40px;
}
#public_page article .col_2_wrap h2{
  margin-top: 0;
  font-size: 22px;
}
#public_page .top_main_content section{
  margin-bottom: 100px;
}
#public_page .recommend_item_list_area .recommend_item_card{
  display: inline-block;
  max-width: calc((100% / 4) - 10px);
  width: calc((60vw / 4) - 10px);
  margin: 10px 5px;
  vertical-align: top;
  cursor: pointer;
}
#public_page .recommend_item_list_area .recommend_item_card.big_card{
  max-width: calc((100% / 3) - 10px);
  width: calc((60vw / 3) - 10px);
}
#public_page .recommend_item_list_area .recommend_item_card .thumbnail_area{
  width: 100%;
  height: calc((60vw / 4) - 10px);
  background-position: center;
  background-size: cover;
}
#public_page .recommend_item_list_area .recommend_item_card.big_card .thumbnail_area{
  width: 100%;
  height: calc((60vw / 3) - 10px);
}
#public_page .recommend_item_list_area .recommend_item_card .item_name{
  font-size: 16px;
}
#public_page .recommend_item_list_area .recommend_item_card .item_price{
  text-align: right;
}
#public_page .shop_introduce_area{
  margin-top: 100px;
}
#public_page .shop_introduce_area .wrap{
  background-color: #EDEAE4;
  padding: 20px;
}
#public_page .shop_introduce_area h2{
  text-align: center;
  border-bottom: 1px dashed #A69477;
  padding-bottom: 20px;
}
#public_page .shop_introduce_area h2 span{
  color: #FFFFFF;
  background-color: #00584D;
  margin: 0 2px;
  padding: 3px 6px 3px 5px;
}
#public_page .shop_introduce_area h2 span:nth-of-type(1){
  margin-left: 5px;
}
#public_page .shop_introduce_area .text_area{
  margin-top: 30px;
}
#public_page .shop_introduce_area .text_area img{
  float: right;
  border: 8px solid #FFFFFF;
  width: 40%;
  margin-left: 10px;
}
#public_page .top_main_content .news_area h2{
  text-align: center;
  font-size: 22px;
  color: #00584D;
  padding: 20px 0;
  background-color: #CCDEDB;
  border-bottom: 3px solid #669B94;
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
#public_page .content_html img{
  max-width: 100%;
}
#public_page .news_area .news_card{
  padding: 0 30px;
  cursor: pointer;
}
#public_page .news_area .news_card p{
  margin: 5px 0;
}
#public_page .news_area .news_card .date{
  margin-right: 30px;
  font-size: 14px;
  color: #A69477;
}
#public_page .news_area .news_card .title{
  font-size: 18px;
  color: #00584D;
}
#public_page .top_main_content .news_area .news_card .content_html *{
  display: inline-block;
}
#public_page .btn_type_1{
  position: relative;
  border: none;
  padding: 13px 60px;
  display: block;
  margin: 0 auto;
  background-color: #00584D;
  color: #FFFFFF;
  cursor: pointer;
}
#public_page .btn_type_1::after{
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid #FFFFFF;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  right: 7px;
  top: calc(50% - 5px);
}
#public_page .top_main_content .news_area button{
  margin-top: 50px;
}
#public_page .item_list_area .item_card{
  display: inline-block;
  max-width: calc((100% / 4) - 10px);
  width: calc((60vw / 4) - 10px);
  margin: 10px 5px;
  vertical-align: top;
  cursor: pointer;
}
#public_page .item_list_area .item_card .thumbnail_area{
  width: 100%;
  height: calc((60vw / 4) - 10px);
  background-position: center;
  background-size: cover;
}
#public_page .item_list_area .item_card .item_name{
  font-size: 16px;
}
#public_page .item_list_area .item_card .item_price{
  text-align: right;
}
#public_page .category_card{
  display: inline-block;
  width: calc((100% / 3) - 60px);
  border: 5px solid #00584D;
  margin: 10px;
  padding: 30px 15px;
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: right 30px center;
  cursor: pointer;
}
#public_page .category_card span{
  font-size: 22px;
  position: relative;
  margin-left: 10px;
  letter-spacing: 3px;
}
#public_page .category_card span::after{
  content: '';
  position: absolute;
  width: calc(100% + 10px);
  border-bottom: 10px solid rgba(153,188,184,.9);
  bottom: 0;
  left: -5px;
  z-index: -1;
}

/************************
フッター
************************/
#public_page footer{
  background-color: #00584D;
  padding-top: 40px;
  color: #FFFFFF;
}
#public_page footer .footer_menu{
  display: flex;
  align-items: center;
}
#public_page footer .footer_menu ul{
  list-style: none;
  padding: 0;
  margin-right: 30px;
}
#public_page footer .footer_menu ul li{
  display: inline-block;
  margin: 0 20px;
  cursor: pointer;
}
#public_page footer .footer_menu .media_area span{
  background-color: #FFFFFF;
  color: #00584D;
  padding: 5px 7px;
  margin-right: 10px;
}
#public_page footer .footer_menu .media_area img{
  height: 25px;
  vertical-align: middle;
  margin: 0 10px;
  padding-bottom: 5px;
  cursor: pointer;
}
#public_page footer .contact_infomation_area{
  display: flex;
  text-align: center;
  justify-content: center;
}
#public_page footer .contact_infomation_area{
  margin-top: 80px;
}
#public_page footer .contact_infomation_area .telnumber_area{
  margin: 0 40px;
}
#public_page footer .contact_infomation_area p{
  margin: 0;
}
#public_page footer .contact_infomation_area .telnumber_area .telnumber{
  background-position: left center;
  background-size: 28px;
  background-repeat: no-repeat;
  font-size: 30px;
  padding-left: 40px;
  text-align: left;
  padding-top: 5px;
  margin: 0;
}
#public_page footer .contact_infomation_area .mailform_area{
  margin: 0 40px;
}
#public_page footer .contact_infomation_area .mailform_area .mailform{
  background-position: left center;
  background-size: 30px;
  background-repeat: no-repeat;
  padding-left: 45px;
  margin-top: 15px;
}
#public_page .btn_type_2{
  position: relative;
  border: 1px solid #FFFFFF;
  background-color: initial;
  padding: 8px 100px;
  display: block;
  margin: 0 auto;
  color: #FFFFFF;
  cursor: pointer;
}
#public_page .btn_type_2::after{
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid #FFFFFF;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  right: 7px;
  top: calc(50% - 5px);
}
#public_page footer .logo_image_area{
  text-align: center;
  margin-top: 80px;
}
#public_page footer .logo_image_area img{
  width: 150px;
}
#public_page footer .copylight_area{
  margin-top: 40px;
  text-align: center;
  background-color: #002C27;
  padding: 5px 0;
}
#public_page footer .copylight_area p{
  margin: 0;
  font-size: 14px;
}

/************************
１カラム
************************/
#public_page #temp_1col article,
#public_page #temp_2col article{
  padding-top: 114px;
  margin-top: 0;
}
#public_page #temp_1col h1{
  text-align: center;
  font-size: 32px;
}
#public_page #temp_1col section{
  margin-bottom: 100px;
}
#public_page #temp_1col section h2{
  color: #00584D;
  border-bottom: 1px dashed #00584D;
}
#public_page #temp_1col section ul{
  list-style: decimal;
}

/************************
２カラム
************************/
#public_page #temp_2col h1{
  text-align: center;
  font-size: 32px;
}
#public_page #temp_2col section{
  margin-bottom: 100px;
}

/************************
特定商取引法に基づく表記
************************/
#public_page #commercial_transaction_page table{
  border-collapse: collapse;
  text-align: left;
}
#public_page #commercial_transaction_page th,
#public_page #commercial_transaction_page td{
  border-top: 1px solid #CCCCCC;
}
#public_page #commercial_transaction_page tr:last-child th,
#public_page #commercial_transaction_page tr:last-child td{
  border-bottom: 1px solid #CCCCCC;
}
#public_page #commercial_transaction_page th{
  background-color: #F2F2F2;
  width: 250px;
  padding: 40px 20px;
}
#public_page #commercial_transaction_page td{
  padding: 40px 20px;
}

/************************
プライバシーポリシー
************************/
#public_page #privacy_policy_page .introduction{
  text-align: center;
  width: 60%;
  margin: 60px auto;
}
#public_page #privacy_policy_page section h2{
  border-bottom: none;
}


/************************
商品一覧
************************/
#public_page #item_list_page .sort_area{
  text-align: right;
}
#public_page #item_list_page .sort_area ul{
  list-style: none;
  display: inline-block;
  padding: 0;
}
#public_page #item_list_page .sort_area ul li{
  display: inline-block;
  padding: 0 15px;
  border-right: 1px solid #000000;
  cursor: pointer;
}
#public_page #item_list_page .sort_area ul li:last-child{
  border-right: none;
  padding-right: 0;
}
#public_page #item_list_page .sort_area ul li.active{
  color: #00584D;
}
#public_page .list_type_1 .item_card{
  display: inline-block;
  max-width: calc((100% / 3) - 10px);
  width: calc((60vw / 3) - 10px);
  margin: 10px 5px;
  vertical-align: top;
  cursor: pointer;
}
#public_page .list_type_1 .item_card .thumbnail_area{
  width: 100%;
  height: calc((60vw / 3) - 10px);
  background-position: center;
  background-size: cover;
}
#public_page .list_type_1 .item_card .item_name{
  font-size: 16px;
}
#public_page .list_type_1 .item_card .item_price{
  text-align: right;
}
#public_page .list_type_1 .nostock .thumbnail_area{
  position: relative;
}
#public_page .list_type_1 .nostock .thumbnail_area::after{
  content: '売り切れ中';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  color: #FFFFFF;
  padding-top: 50%;
  box-sizing: border-box;
}


/************************
商品詳細
************************/
#public_page #item_detail_page .main_image_area{
  width: 100%;
  height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#public_page #item_detail_page section{
  margin: 20px 0;
}
#public_page #item_detail_page .item_info_area{
  display: flex;
}
#public_page #item_detail_page .item_info_area .left_content{
  flex: 1;
  margin-right: 20px;
}
#public_page #item_detail_page .item_info_area .left_content p{
  display: inline-block;
  margin: 0;
}
#public_page #item_detail_page .item_info_area .left_content p.item_serial{
  margin-right: 20px;
  margin-bottom: 10px;
}
#public_page #item_detail_page .item_info_area .left_content p.name{
  display: block;
  margin: 10px 0;
  font-size: 22px;
}
#public_page #item_detail_page .item_info_area .left_content p.name .sold_out{
  color: #FF0000;
  font-size: 12px;
  vertical-align: baseline;
}
#public_page #item_detail_page .item_info_area .left_content p.price{
  display: block;
  margin: 10px 0;
  font-size: 22px;
}
#public_page #item_detail_page .item_info_area .left_content p.postage{
border: 1px solid #A14213;
color: #A14213;
border-radius: 5px;
padding: 3px 6px;
font-size: 14px;
}
#public_page #item_detail_page .item_info_area .left_content .item_description{
  margin: 35px 0;
  }
#public_page #item_detail_page .item_info_area .right_content{
  width: 300px;
}
#public_page #item_detail_page .item_info_area .right_content .wrap{
  background-color: #EDEAE4;
  padding: 45px 25px;
}
#public_page #item_detail_page .item_info_area .right_content table{
  font-size: 14px;
}
#public_page #item_detail_page .item_info_area .right_content th{
  width: 4em;
  text-align: left;
}
#public_page #item_detail_page .item_info_area .right_content .price{
  font-size: 20px;
}
#public_page #item_detail_page .item_info_area .right_content .btn_area{
  margin-top: 30px;
}
#public_page #item_detail_page .item_info_area .right_content .btn_area .insert_cart_btn{
  background-position: left 30px center;
  background-repeat: no-repeat;
  background-size: 20px;
  font-size: 16px;
  border: none;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 20px;
  padding-left: 40px;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
}
#public_page #item_detail_page .item_info_area .right_content .btn_area .soldout_btn{
  background-position: left 30px center;
  background-repeat: no-repeat;
  background-size: 20px;
  font-size: 16px;
  border: 3px solid #A14213;
  background-color: #CCCCCC;
  color: #A14213;
  padding: 20px;
  padding-left: 40px;
  width: 100%;
  border-radius: 3px;
  cursor: inherit;
}
#public_page #item_detail_page .item_info_area .right_content .btn_area .insert_favorite_btn{
  background-position: left 50px center;
  background-repeat: no-repeat;
  background-size: 20px;
  background-color: #FFFFFF;
  border: 2px solid#00584D;
  color: #00584D;
  padding: 7px;
  padding-left: 40px;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;
  cursor: pointer;
}
#public_page #item_detail_page .image_area .item_image_area{
  height: 450px;
  margin-bottom: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
/* 
*************************
お知らせ
************************* */
#public_page #news_list_page .news_card{
  display: flex;
  border-bottom: 1px dashed #00584D;
  padding: 40px 0;
}
#public_page #news_list_page .news_card:last-child{
  border-bottom: none;
}
#public_page #news_list_page .news_card .left_content{
  flex: 1;
}
#public_page #news_list_page .news_card .left_content .date{
  font-size: 12px;
}
#public_page #news_list_page .news_card .left_content .date .new_tag{
  background-color: #A14213;
  color: #FFFFFF;
  padding: 2px 5px;
  margin-left: 15px;
}
#public_page #news_list_page .news_card .left_content .title{
  font-size: 18px;
  color: #00584D;
}
#public_page #news_list_page .news_card .left_content .news_body_html{
  font-size: 14px;
}
#public_page #news_list_page .news_card .left_content .news_detail_link_btn{
  margin-top: 20px;
  border: none;
  color: #00584D;
  background-color: inherit;
  cursor: pointer;
}
#public_page #news_list_page .news_card .right_content{
  width: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 20px;
}
#public_page #news_detail_page .date{
  font-size: 14px;
  padding-left: 20px;
}
#public_page #news_detail_page .date .new_tag{
  background-color: #A14213;
  color: #FFFFFF;
  padding: 2px 5px;
  margin-left: 15px;
  font-size: 12px;
}
#public_page #news_detail_page .title{
  font-size: 20px;
  color: #00584D;
  padding-left: 20px;
  padding-bottom: 30px;
  border-bottom: 1px dashed #00584D;
  margin-top: 30px;
}
#public_page #news_detail_page .body_area{
  padding: 20px;
  padding-bottom: 60px;
  border-bottom: 1px dashed #00584D;
}
#public_page #news_detail_page .news_list_link_btn{
  background-color: #00584D;
  border: none;
  color: #FFFFFF;
  padding: 10px 30px;
  display: block;
  margin: 60px auto 0;
}
#public_page #news_detail_page figcaption{
  text-align: center;
}
#public_page #news_detail_page figure.table table{
  width: 100%;
  border-collapse: collapse !important;
  border-spacing: 0 !important;
}
#public_page #news_detail_page figure.table th,
#public_page #news_detail_page figure.table td{
  padding: 15px;
  border: 1px solid #767676;
}
#public_page #news_detail_page figure.table th{
  text-align: center;
}


/************************
お問い合わせ
************************/
#public_page #contact_page .introduction{
  font-size: 14px;
}
#public_page #contact_page table{
  margin: 20px auto;
  width: 100%;
  border-collapse: collapse;
}
#public_page #contact_page table th,
#public_page #contact_page table td{
  border-top: 1px solid #CCCCCC;
}
#public_page #contact_page table tr:last-child th,
#public_page #contact_page table tr:last-child td{
  border-bottom: 1px solid #CCCCCC;
}
#public_page #contact_page table th{
  width: 250px;
  background-color: #F2F2F2;
}
#public_page #contact_page table th span.required{
  color: #FFFFFF;
  background-color: #FF0000;
  padding: 2px 5px;
  font-size: 14px;
  margin-left: 10px;
}
#public_page #contact_page table th.re_mail_address_input span{
  display: inline-block;
  vertical-align: middle;
}
#public_page #contact_page table td{
  padding: 25px 30px;
}
#public_page #contact_page table td input{
  padding: 5px;
  font-size: 16px;
}
#public_page #contact_page table td .radio_btn_label{
  display: block;
  margin: 10px 0;
}
#public_page #contact_page table td .family_name_area,
#public_page #contact_page table td .first_name_area{
  width: calc((100% / 2) - 60px);
  display: inline-block;
}
#public_page #contact_page table td .first_name_area{
  margin-left: 30px;
}
#public_page #contact_page table td .family_name_area span,
#public_page #contact_page table td .first_name_area span{
  margin-right: 10px;
  width: 2em;
  text-align: right;
  display: inline-block;
}
#public_page #contact_page table td .family_name_area input,
#public_page #contact_page table td .first_name_area input{
  width: calc((100% - 2em) - 30px);
  border: 1px solid #CBCBCB;
}
#public_page #contact_page table td input[name='mail_address'],
#public_page #contact_page table td input[name='re_mail_address']{
  width: 300px;
  margin-right: 15px;
  border: 1px solid #CBCBCB;
}
#public_page #contact_page table td p,
#public_page #contact_page table td p{
  margin: 10px 0 0;
  font-size: 14px;
}
#public_page #contact_page table td textarea{
  width: 100%;
  resize: none;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #CBCBCB;
}
#public_page #contact_page .agree_area{
  text-align: center;
  margin-top: 100px;
}
#public_page #contact_page .agree_area input{
  margin-right: 10px;
}
#public_page #contact_page .agree_area .link_span{
  color: #0000FF;
  text-decoration: underline;
  cursor: pointer;
}
#public_page #contact_page .button_area{
  margin-top: 100px;
  text-align: center;
}
#public_page #contact_page .button_area button{
  width: 150px;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 10px;
  border: none;
  margin: 0 20px;
}
#public_page #contact_page .button_area button.desabled{
  background-color: #CCCCCC;
  pointer-events: none;
}
#public_page #contact_done_page .text_area{
  margin: 60px 0;
}
#public_page #contact_done_page .button_area{
  margin-top: 200px;
  text-align: center;
}
#public_page #contact_done_page .button_area button{
  background-color: #00584D;
  color: #FFFFFF;
  padding: 10px 30px;
  border: none;
  margin: 0 20px;
}



/************************
新規会員登録申請
************************/
#public_page .form_type_2 form{
  margin-top: 50px;
}
#public_page .form_type_2 form dl{
  margin-top: 40px;
}
#public_page .form_type_2 form dt span.required{
  color: #FFFFFF;
  background-color: #FF0000;
  padding: 2px 5px;
  font-size: 14px;
  margin-left: 10px;
}
#public_page .form_type_2 form dd{
  margin: 10px 0;
  width: 100%;
}
#public_page .form_type_2 form dd input{
  width: 100%;
  padding: 5px;
  border: 1px solid #CBCBCB;
}
#public_page .form_type_2 .reissue_application_link_btn{
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
  text-decoration: underline;
}
#public_page .form_type_2 .reissue_application_link_btn:hover{
  color: #767676;
}

#public_page .form_type_2 .button_area{
  margin-top: 100px;
  text-align: center;
}
#public_page .form_type_2 .button_area button{
  width: 150px;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 10px;
  border: none;
  margin: 0 20px;
}
#public_page .form_type_2 .button_area button.desabled{
  background-color: #CCCCCC;
  pointer-events: none;
}



/************************
新規会員登録申請完了
************************/
#public_page #signup_application_done_page .link_btn{
  color: #A14213;
  text-decoration: underline;
  cursor: pointer;
}
#public_page #signup_application_done_page .telnumber{
  color: #A14213;
}
#public_page #signup_application_done_page .button_area{
  margin-top: 100px;
  text-align: center;
}
#public_page #signup_application_done_page .button_area button{
  width: 150px;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 10px;
  border: none;
  margin: 0 20px;
}



/************************
新規会員登録
************************/
#public_page .form_type_1 .introduction{
  font-size: 14px;
}
#public_page .form_type_1 table{
  margin: 20px auto;
  width: 100%;
  border-collapse: collapse;
}
#public_page .form_type_1 table th,
#public_page .form_type_1 table td{
  border-top: 1px solid #CCCCCC;
}
#public_page .form_type_1 table tr:last-child th,
#public_page .form_type_1 table tr:last-child td{
  border-bottom: 1px solid #CCCCCC;
}
#public_page .form_type_1 table th{
  width: 250px;
  background-color: #F2F2F2;
}
#public_page .form_type_1 table th span.required{
  color: #FFFFFF;
  background-color: #FF0000;
  padding: 2px 5px;
  font-size: 14px;
  margin-left: 10px;
  display: inline-block;
}
#public_page .form_type_1 table th.re_password_input span{
  display: inline-block;
  vertical-align: middle;
}
#public_page .form_type_1 table td{
  padding: 25px 30px;
}
#public_page .form_type_1 table td input{
  padding: 5px;
  font-size: 16px;
  border: 1px solid #CBCBCB;
}
#public_page .form_type_1 table td .radio_btn_label{
  display: block;
  margin: 10px 0;
}
#public_page .form_type_1 table td .family_name_area,
#public_page .form_type_1 table td .first_name_area{
  width: calc((100% / 2) - 60px);
  display: inline-block;
}
#public_page .form_type_1 table td .first_name_area{
  margin-left: 30px;
}
#public_page .form_type_1 table td .family_name_area span,
#public_page .form_type_1 table td .first_name_area span{
  margin-right: 10px;
  width: 2em;
  text-align: right;
  display: inline-block;
}
#public_page .form_type_1 table td .family_name_area input,
#public_page .form_type_1 table td .first_name_area input{
  width: calc((100% - 2em) - 30px);
}
#public_page .form_type_1 table td input[name='password'],
#public_page .form_type_1 table td input[name='re_password'],
#public_page .form_type_1 table td input[name='mail_address'],
#public_page .form_type_1 table td input[name='re_mail_address'],
#public_page .form_type_1 table td input[name='birthday']{
  width: 300px;
  margin-right: 15px;
}
#public_page .form_type_1 table td input[name='postal_code']{
  width: 100px;
  margin-left: 15px;
}
#public_page .form_type_1 table td input[name='address']{
  width: 80%;
}
#public_page .form_type_1 table td p,
#public_page .form_type_1 table td p{
  margin: 10px 0 0;
  font-size: 14px;
}
#public_page .form_type_1 table td textarea{
  width: 100%;
  resize: none;
  padding: 5px;
  font-size: 16px;
}
#public_page .form_type_1 .agree_area{
  text-align: center;
  margin-top: 100px;
}
#public_page .form_type_1 .agree_area input{
  margin-right: 10px;
}
#public_page .form_type_1 .agree_area .link_span{
  color: #0000FF;
  text-decoration: underline;
  cursor: pointer;
}
#public_page .form_type_1 .button_area{
  margin-top: 100px;
  text-align: center;
}
#public_page .form_type_1 .button_area button{
  width: 150px;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 10px;
  border: none;
  margin: 0 20px;
}
#public_page .form_type_1 .button_area button.desabled{
  background-color: #CCCCCC;
  pointer-events: none;
}
#public_page .form_type_1 td button{
  background-color: #00584D;
  color: #FFFFFF;
  padding: 9px 30px;
  border: none;
}
#public_page #signup_page .form_type_1 tr:nth-child(6) td button{
  margin-left: 15px;
}



/************************
新規会員登録完了
************************/
#public_page #signup_done_page .link_btn{
  color: #A14213;
  text-decoration: underline;
  cursor: pointer;
}
#public_page #signup_done_page .telnumber{
  color: #A14213;
}
#public_page #signup_done_page .button_area{
  margin-top: 100px;
  text-align: center;
}
#public_page #signup_done_page .button_area button{
  width: 150px;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 10px;
  border: none;
  margin: 0 20px;
}




/************************
マイページ・会員情報変更
************************/
#public_page #member_edit_page .form_type_1 td button{
  background-color: #00584D;
  color: #FFFFFF;
  padding: 10px 30px;
  border: none;
}
#public_page #member_edit_page .form_type_1 .button_area button{
  width: 200px;
}
#public_page #member_edit_page .form_type_1 tr:nth-child(4) td button{
  margin-left: 15px;
  padding: 9px 30px;
}


/************************
マイページ・お届け先リスト
************************/
#public_page #temp_1col section.search_add_area{
  margin-top: 50px;
  margin-bottom: 30px;
}
#public_page .search_add_area{
  display: flex;
}
#public_page .search_add_area .left_content input{
  padding: 5px;
  width: 250px;
  border: 1px solid #CBCBCB;
}
#public_page .search_add_area .left_content button{
  margin-left: 10px;
  padding: 6px 30px;
  letter-spacing: 3px;
  border: none;
  color: #FFFFFF;
  background-color: #A14213;
}
#public_page .search_add_area .right_content{
  margin-left: auto;
}
#public_page .search_add_area .right_content button{
  background-size: 15px;
  background-position: left 30px center;
  background-repeat: no-repeat;
  padding: 5px 40px 5px 55px;
  background-color: #00584D;
  color: #FFFFFF;
  border: none;
}
#public_page .list_area table{
  width: 100%;
  border-collapse: collapse;
}
#public_page .list_area table th{
  background-color: #EDEAD3;
  border: 1px solid #8C8C8C;
  padding: 10px 0;
}
#public_page .list_area table td{
  border: 1px solid #8C8C8C;
  padding: 10px 5px;
}
#delivery_address_list_page .list_area table th:nth-child(1),
#delivery_address_list_page .list_area table td:nth-child(1){
  width: 12%;
}
#delivery_address_list_page .list_area table th:nth-child(2),
#delivery_address_list_page .list_area table td:nth-child(2){
  width: 20%;
}
#delivery_address_list_page .list_area table th:nth-child(4),
#delivery_address_list_page .list_area table td:nth-child(4){
  width: 18%;
}
#delivery_address_list_page .list_area table th:nth-child(5),
#delivery_address_list_page .list_area table td:nth-child(5){
  width: 12%;
}
#public_page .list_area table td.checkbox_box{
  text-align: center;
  width: 50px;
}
#public_page .list_area table td.checkbox_box input{
  margin: 0;
}
#public_page .list_area table td.name_box{
  text-align: center;
}
#public_page .list_area table td.address_box{
  padding-left: 30px;
}
#public_page .list_area table td.telnumber_box{
  text-align: center;
}
#public_page .list_area table td.button_box{
  text-align: center;
  width: 100px;
}
#public_page .list_area table td.button_box{
  padding-top: 20px;
  padding-bottom: 20px;
}
#public_page .list_area table td.button_box button{
  display: block;
  margin: auto;
  background-color: #FFFFFF;
  padding: 3px 10px;
  border-style: solid;
}
#public_page .list_area table td.button_box button:first-child{
  margin-bottom: 10px;
}
#public_page .list_area table td.button_box button.delete_btn{
  color: #FF0000;
  border-color: #FF0000;
}
#public_page #mailing_list_edit_page .list_area table td.button_box button.delete_btn{
  margin-bottom: 0;
}
#public_page .list_area table td.button_box button.add_btn{
  margin-bottom: 0;
}
#public_page .list_area table td.name_box span.margin_right_10px{
  margin-right: 10px;
}
#public_page .under_menu_area{
  display: flex;
  margin-top: 30px;
}
#public_page .under_menu_area .left_group{
  display: flex;
}
#public_page .under_menu_area .left_group select {
  padding: 10px 5px;
  margin-right: 10px;
  border: 1px solid #CBCBCB;
}
#public_page .under_menu_area .left_group button {
  padding: 10px 25px;
  letter-spacing: 5px;
  color: #FFFFFF;
  background-color: #373239;
  border: none;
  cursor: pointer;
}
#public_page .under_menu_area .left_group button.add_btn {
  padding: 10px 30px 10px 50px;
  color: #FFFFFF;
  letter-spacing: 2px;
  background-color: #00584D;
  border: none;
  cursor: pointer;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 23px center;
}
#public_page .under_menu_area .right_group{
  margin-left: auto;
}
#public_page .under_menu_area .right_group .paging_area{
  margin: 0;
}
#public_page .under_menu_area .right_group .paging_area button{
  border: none;
  margin-left: 10px;
}
#public_page .under_menu_area .right_group .paging_area .active{
  background-color: #00584D;
}
#public_page .under_menu_area .right_group .paging_area button:hover{
  background-color: #00584D;
  color: #FFFFFF;
}
#public_page .under_menu_area .right_group .paging_area .pre_btn,
#public_page .under_menu_area .right_group .paging_area .next_btn{
  padding-left: 20px;
  padding-right: 20px;
  background-color: #00584D;
  color: #FFFFFF;
}

/************************
マイページ・お届け先新規登録
************************/
#public_page #delivery_address_add_page .form_type_1 tr:nth-child(3) td button{
  margin-left: 15px;
}

/************************
マイページ・お気に入り一覧
************************/
#public_page #item_list_page .item_card{
  margin-bottom: 30px;
}
#public_page #item_list_page .item_card .thumbnail_area{
  position: relative;
}
#public_page #item_list_page .item_card .thumbnail_area img{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  z-index: 100;
  cursor: pointer;
}
#public_page #item_list_page .item_card p{
  text-align: center;
}
#public_page #item_list_page .item_card .btn_area{
  text-align: center;
}
#public_page #item_list_page .item_card .btn_area button{
  background-position: left 20px center;
  background-repeat: no-repeat;
  background-size: 20px;
  font-size: 14px;
  border: none;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 10px 16px;
  padding-left: 40px;
  width: 80%;
  border-radius: 3px;
  cursor: pointer;
  border: 2px solid #00584D;
}
#public_page #item_list_page .item_card .btn_area button.soldout_btn{
  border: 2px solid #A14213;
  background-color: #CCCCCC;
  color: #A14213;
  cursor: initial;
  padding-left: 20px;
}

/************************
マイページ・買い物カゴ
************************/
#public_page #order_flow_area .wrap{
  border-top: 1px solid #B3B3B3;
  border-bottom: 1px solid #B3B3B3;
  padding: 30px 0;
}
#public_page #order_flow_area ul{
  list-style: none !important;
  text-align: center;
}
#public_page #order_flow_area ul li{
  display: inline-block;
  margin: 0 20px;
  color: #B3B3B3;
  font-size: 18px;
}
#public_page #order_flow_area ul li.active{
  color: #00584D;
}
#public_page #order_flow_area ul li .number{
  padding: 2.5px 6px;
  border: 1px solid;
  margin-right: 10px;
}
#public_page #order_flow_area ul li.active .number{
  background-color: #00584D;
  color: #FFFFFF;
}
#public_page #order_flow_area ul li .label{
  font-size: 14px;
}
#public_page .list_type_2 table{
  width: 100%;
  border-collapse: collapse;
}
#public_page .list_type_2 th{
  background-color: #343434;
  color: #FFFFFF;
  border: 1px solid #999999;
  padding: 10px 0;
}
#public_page .list_type_2 th.item_name_th{
  width: 70%;
}
#public_page .list_type_2 th.delete_btn_th{
  width: 80px;
}
#public_page .list_type_2 td{
  padding: 10px;
  border: 1px solid #999999;
}
#public_page .list_type_2 td.item_image_name_box div{
  margin-left: 40px;
  display: inline-block;
  width: calc((100% - 200px) - 40px);
}
#public_page .list_type_2 td.item_image_name_box div .name{
  margin-top: 0;
}
#public_page .list_type_2 td img{
  width: 200px;
  vertical-align: top;
}
#public_page .list_type_2 td.price_box{
  text-align: center;
}
#public_page .list_type_2 td.delete_btn_box{
  text-align: center;
}
#public_page .list_type_2 td button.delete_btn{
  border: none;
  background-color: initial;
  font-weight: 700;
  text-decoration: underline;
}
#public_page .list_type_2 .btn_area{
  margin-top: 100px;
  text-align: center;
}
#public_page .list_type_2 .btn_area .item_list_link_btn{
  background-color: #EDEAE4;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
}
#public_page .list_type_2 .btn_area .order_post_info_link_btn{
  background-color: #00584D;
  color: #FFFFFF;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
}



/************************
マイページ・お届け先情報の入力
************************/
#public_page .order_post_info_content .introduction button{
  background-color: #00584D;
  color: #FFFFFF;
  padding: 10px 15px;
  border: none;
  margin-left: 20px;
}
#public_page .list_type_3 .order_card_box{
  border: 1px solid #000000;
  padding: 20px;
}
#public_page .list_type_3 .order_card_box h2{
  border: none !important;
  color: #000000 !important;
  margin-top: 5px;
}
#public_page .list_type_3 .btn_area{
  margin-top: 100px;
  text-align: center;
}
#public_page .list_type_3 .btn_area .cart_list_link_btn{
  background-color: #EDEAE4;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
}
#public_page .list_type_3 .btn_area .order_info_link_btn{
  background-color: #00584D;
  color: #FFFFFF;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
}
#public_page .list_type_3 .order_card{
  margin-bottom: 60px;
}
#public_page .list_type_3 .order_card .post_info{
  margin-bottom: 10px;
  display: flex;
}
#public_page .list_type_3 .order_card h3{
  display: inline-block;
  margin: 0;
  margin-right: 40px;
}
#public_page .list_type_3 .order_card p{
  display: inline-block;
  margin: 0;
  margin-right: 20px;
}
#public_page .list_type_3 .order_card .postal_code{
  margin-right: 10px;
}
#public_page .list_type_3 .order_card .delete_btn{
  background-color: #FF0000;
  color: #FFFFFF;
  border: none;
  letter-spacing: 2px;
  margin-left: auto;
  margin-right: 20px;
  padding: 0 14px;
  font-weight: bold;
  /* width: 61px; */
  /* height: 30px; */
  display: block;
}
#public_page .list_type_3 .order_card .item_list{
  border: 1px solid #CCCCCC;
  padding: 20px;
}
#public_page .list_type_3 .order_card form{
  margin-top: 20px;
  border-bottom: 1px dashed #CBCBCB;
  padding-bottom: 10px;
}
#public_page .list_type_3 .order_card form p{
  width: 100%;
}
#public_page .list_type_3 .order_card form .item_name{
  margin-right: 20px;
  width: 50%;
  display: inline-block;
}
#public_page .list_type_3 .order_card form .item_standard{
  margin-right: 20px;
  width: 24%;
  display: inline-block;
}
#public_page .list_type_3 .order_card form input{
  width: 2em;
  margin-left: 10px;
  text-align: right;
  padding: 0 3px;
  vertical-align: text-bottom;
  border: 1px solid #CBCBCB;
}



/************************
マイページ・お届け先の追加
************************/
#public_page .add_order_post_address_content form{
  margin-bottom: 100px;
}
#public_page .add_address_btn_area{
  margin: 60px 0 40px;
  text-align: center;
}
#public_page .add_address_btn_area button{
  background-color: #00584D;
  color: #FFFFFF;
  padding: 15px;
  border: none;
  margin: 0 40px;
}
#public_page .add_form_btn_area{
  margin-top: 80px;
}
#public_page .add_form_btn_area button{
  width: 100%;
  background-color: #A14213;
  background-position: left calc(50% - 8em) center;
  background-repeat: no-repeat;
  background-size: 20px;
  padding: 15px;
  color: #FFFFFF;
  border: none;
  font-size: 18px;
}
#public_page .btn_area{
  text-align: center;
  margin-top: 150px;
}
#public_page .order_post_info_link_btn{
  background-color: #EDEAE4;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
  /* margin: 40px auto; */
}
#public_page .add_btn{
  background-color: #00584D;
  color: #FFFFFF;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
  /* margin: 40px auto; */
}
#public_page #add_order_post_address_page .form_type_1 tr:nth-child(3) td button.postal_num_btn{
  margin-left: 15px;
}


/************************
マイページ・お支払方法の入力
************************/
#public_page .order_post_info_link_btn{
  background-color: #EDEAE4;
  padding: 15px;
  width: 220px;
  border: none;
  /* margin: 0 40px; */
  margin: 40px auto;
}
#public_page .order_confirm_link_btn{
  background-color: #00584D;
  color: #FFFFFF;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 40px;
}



/************************
マイページ・入力内容の確認
************************/
#public_page .order_confirm_content h2{
  color: #000000 !important;
  border-bottom: none !important;
}
#public_page .order_card_box .order_card{
  margin-bottom: 40px;
}
#public_page .order_card_box .order_card dt,
#public_page .order_card_box .order_card dd{
  border: 1px solid #999999;
  padding: 10px 20px;
}
#public_page .order_card_box .order_card dt{
  background-color: #EDEAE4;
}
#public_page .order_card_box .order_card dt.post_title{
  background-color: #CCDEDB;
}
#public_page .order_card_box .order_card dd{
  margin: 0;
  border-top: none;
  border-bottom: none;
}
#public_page .order_card_box .order_card dd:last-child{
  border-bottom: 1px solid #999999;
}
#public_page .order_card_box .order_card dd p{
  margin: 5px 0;
}
#public_page .order_card_box .order_card dd p.post_name{
  margin-top: 15px;
  margin-bottom: 20px;
}
#public_page .order_card_box .order_card dd p.item_info{
  margin-bottom: 20px;
  border-bottom: 1px dashed #CBCBCB;
  padding-bottom: 10px;
}
#public_page .order_card_box .order_card dd span{
  display: inline-block;
  margin-right: 30px;
  text-align: right;
}
#public_page .order_card_box .order_card dd span.item_name{
  width: 30%;
  text-align: left;
  vertical-align: top;
}
#public_page .order_card_box .order_card dd span.standard{
  width: 15%;
}
#public_page .order_card_box .order_card dd span.number{
  width: 10%;
}
#public_page .order_card_box .order_card dd span.price{
  width: 15%;
}
#public_page .pay_type_box{
  margin-top: 60px;
}
#public_page .pay_type_box dl{
  display: flex;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #999999;
}
#public_page .pay_type_box dt{
  width: 30%;
  max-width: 200px;
  background-color: #CCDEDB;
  padding: 20px;
}
#public_page .pay_type_box dd{
  flex: 1;
  margin: 0;
  padding: 20px 40px;
}
#public_page .total_price_box{
  margin-top: 60px;
}
#public_page .total_price_box dl{
  display: flex;
  border-top: 1px solid #999999;
  margin: 0;
}
#public_page .total_price_box dl:last-child{
  border-bottom: 1px solid #999999;
}
#public_page .total_price_box dt{
  width: 40%;
  max-width: 350px;
  background-color: #EDEAE4;
  padding: 20px;
  text-align: center;
}
#public_page .total_price_box dd{
  flex: 1;
  margin: 0;
  padding: 20px 40px;
  text-align: center;
}
#public_page .total_price_box dd.total_price{
  font-size: 22px;
}

#public_page .order_info_link_btn{
  background-color: #EDEAE4;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
}
#public_page .order_decide_btn{
  background-color: #00584D;
  color: #FFFFFF;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
}



/************************
マイページ・注文完了
************************/
#public_page #order_completed_page .link_btn{
  color: #A14213;
  text-decoration: underline;
  cursor: pointer;
}
#public_page #order_completed_page .telnumber{
  color: #A14213;
}
#public_page #order_completed_page .button_area{
  margin-top: 100px;
  text-align: center;
}
#public_page #order_completed_page .button_area button{
  width: 150px;
  background-color: #00584D;
  color: #FFFFFF;
  padding: 10px;
  border: none;
  margin: 0 20px;
}
#public_page .popup_area{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
}
#public_page .popup_area .shadow_wrap{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  display: flex;
}
#public_page .popup_area .popup_content{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6vw;
  background-color: #FFFFFF;
  padding: 60px 40px;
  width: 70%;
}
#public_page .popup_area .popup_content h2{
  margin: 0;
  margin-bottom: 60px;
}
#public_page .popup_area .popup_content table{
  margin: 0;
  margin-bottom: 60px;
}
#public_page .popup_area .btn_area{
  margin-top: 40px;
}
#public_page .popup_area .no_add_btn{
  background-color: #EDEAE4;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 40px auto;
}
/************************
マイページ・購入履歴
************************/
#public_page #order_history_page .list_area span{
  display: block;
}
#public_page #order_history_page .list_area .datetime,
#public_page #order_history_page .list_area .order_number{
  text-align: center;
}
/* #public_page #order_history_page .list_area td:nth-child(1){
  width: 20%;
} */
#public_page #order_history_page .list_area td:nth-child(2){
  width: 60%;
}
#public_page #order_history_page .list_area td:nth-child(3){
  width: 10%;
  text-align: center;
}
#public_page #order_history_page .list_area td:nth-child(4){
  width: 18%;
  text-align: center;
}
#public_page #order_history_page .list_area button{
  margin: 20px auto;
  display: table;
  border: 1px solid #00584D;
  background-color: #00584D;
  color: #FFF;
  padding: 10px;
  width: 85%;
}
#public_page #order_history_page .list_area div{
  display: inline-block;
  vertical-align: top;
  margin: 0 15px;
}
#public_page #order_history_page .list_area .thumbnail_area{
  width: 35%;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}





/*
******************************
レスポンシブ対応　2021.11.5 add
****************************** */

@media screen and (max-width: 1200px){
  /* 一般ぺージ(共通) */
  #public_page .subline_1200{
    max-width: 100%;
  }
  /* 管理画面(サイドバー) */
  #admins_page aside.sidebar li{
    line-height: 1.5em;
    padding: 15px 8px;
  }
}
@media screen and (max-width: 1024px){
  /* 管理画面(共通) */
  #admins_page header{
    background-size: 150px;
  }
  #admins_page .box_type_1 th,
  #admins_page .box_type_1 td{
    padding: 20px;
  }
  #admins_page .box_type_1 .content_area table td.flex span.header{
    width: 95px;
  }
  #admins_page .box_type_1 .content_area table td.birthday_box span{
    margin-left: 0;
    margin-top: 7px;
    display: block;
  }
  #admins_page .box_type_2 dd .radio_btn_label{
    margin-right: 20px;
  }
  #admins_page #add_form td button,
  #admins_page #update_form td button{
    margin: 10px 0 0;
  }
  /* 管理画面(TOP) */
  #admins_page #home_page .situation_area{
    margin: 0 25px;
  }
  /* 商品一覧(管理画面) */
  #admins_page #item_list_page .list_area table th:nth-child(3){
    width: 180px;
  }
  #admins_page #item_list_page .list_area table th:nth-child(8){
    width: 40px;
  }
  /* 受注・配送管理一覧(管理画面) */
  #admins_page #order_list_page .list_area table th:nth-child(1){
    width: 95px;
  }
  #admins_page #order_list_page .list_area table th:nth-child(2),
  #admins_page #order_list_page .list_area table th:nth-child(6),
  #admins_page #order_list_page .list_area table th:nth-child(7),
  #admins_page #order_list_page .list_area table th:nth-child(8){
    width: 60px;
  }
}
@media screen and (max-width: 1000px){
  /* 一般ぺージ(共通) */
  #public_page .subline_1000{
    max-width: 95%;
    /* margin: 30px auto; */
  }
  /* ヘッダー(一般) */
  #public_page header{
    background-position: center 65px;
    background-size: 55px;
    height: 114px;
    padding-bottom: 15px;
    background-color: #00584D;
  }
  #public_page header .header{
    display: block;
  }
  #public_page header .right_area{
    margin: 0 auto;
    text-align: end;
  }
  #public_page header .right_area button img{
    height: 16px;
  }
  #public_page header .right_area{
    position: absolute;
    top: -7px;
    right: 0;
  }
  #public_page header .login_btn_area,
  #public_page header .member_menu_btn_area{
    bottom: -48px;
    right: -7px;
  }
  /* フッター(一般) */
  #public_page footer .footer_menu{
    display: block;
  }
  #public_page footer .media_area{
    text-align: end;
  }
  #public_page footer .footer_menu ul{
    margin: 0 auto 30px;
  }
  #public_page footer .footer_menu .media_area span{
    padding: 3px 5px;
  }
  #public_page footer .footer_menu .media_area img{
    height: 18px;
  }
  /* 新規会員登録 */
  #public_page .form_type_1 table span{
    display: block;
  }
  /* お気に入り一覧 */
  #public_page .btn_area{
    margin-top: 80px;
  }
}
@media screen and (max-width: 900px){
  /* ヘッダー(一般) */
  #public_page .breadcrumb{
    margin-top: 20px;
  }
  /* フッター(一般) */
  #public_page footer .contact_infomation_area .telnumber_area .telnumber{
    font-size: 34px;
  }
  /* 一般ぺージ(TOP) */
  #public_page .category_card{
    width: calc(100% / 2 - 60px);
  }
  /* 商品一覧 */
  #public_page .list_type_1 .item_card .thumbnail_area{
    height: calc(70vh / 4 - 10px);
  }
  /* 買い物カゴ */
  #public_page #order_flow_area .wrap{
    padding: 15px 0;
  }
  #public_page #temp_1col section ul{
    text-align: left;
  }
  #public_page #order_flow_area ul li{
    margin: 10px 0px;
    width: 185px;
  }
}
@media screen and (max-width: 768px){
  /* ヘッダー(一般)   */
  #public_page header .right_area button{
    font-size: 0.7em;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(1){
    top: 19px;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(2){
    top: 29px;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(3){
    top: 39px;
  }
  #public_page header .right_area button .badge{
    width: 22px;
    height: 20px;
  }
  
  /* 一般ぺージ(共通) */
  #public_page .breadcrumb{
    display: none;
  }
  #public_page #temp_1col h1,
  #public_page #temp_2col h1{
    font-size: 25px;
  }
  /* 一般ぺージ(TOP) */
  #public_page article .col_2_wrap{
    flex-wrap: wrap;
  }
  #public_page article .col_2_wrap aside.sidebar{
    width: 100%;
    position: relative;
    order: 1;
  }
  #public_page article .col_2_wrap main{
    width: 100%;
    padding-left: 0;
    position: relative;
    order: 2;
    padding-bottom: 40px;
  }
  #public_page .category_list_area{
    display: none;
  }
  #public_page .recommend_item_list_area .recommend_item_card,
  #public_page .recommend_item_list_area .recommend_item_card.big_card{
    width: calc(100% / 3 - 10px);
    max-width: calc(100% / 3 - 10px);
  }
  #public_page .recommend_item_list_area .recommend_item_card .thumbnail_area,
  #public_page .recommend_item_list_area .recommend_item_card.big_card .thumbnail_area{
    height: calc(70vh / 3 - 10px);
  }
  #public_page .item_list_area .item_card{
    width: calc(100% / 3 - 10px);
    max-width: calc(100% / 3 - 10px);
  }
  #public_page .item_list_area .item_card .thumbnail_area{
    height: calc(70vh / 3 - 10px);
  }
  /* サイドバー(一般) */
  #public_page article .col_2_wrap aside.sidebar ul li{
    display: inline-block;
    width: calc(100% / 3 - 60px);
    margin: 10px;
    padding: 15px;
    background-position: right 15px center;
    background-size: 35px;
  }
  #public_page article .col_2_wrap aside.sidebar ul li:nth-child(5){
    background-size: 20px !important;
    background-position: right 19px center !important;
  }
  #public_page article .col_2_wrap aside.sidebar .search_area button{
    padding: 15px 20px;
  }
  /* フッター(一般) */
  #public_page footer .contact_infomation_area{
    display: block;
  }
  #public_page footer .contact_infomation_area .telnumber_area .telnumber{

    display: table;
    margin: 0 auto;
  }
  #public_page footer .contact_infomation_area .mailform_area .mailform{
    display: table;
    margin: 15px auto;
  }
  #public_page footer .logo_image_area img{
    width: 28%;
  }
  #public_page footer .footer_menu ul{
    display: inline-block;
    width: calc(100% / 2 + 10px);
  }
  #public_page footer .footer_menu ul li{
    display: block;
  }
  #public_page footer .media_area{
    display: inline-block;
    width: calc(100% / 2 - 10px);
    vertical-align: top;
  }
  
  /* 特定商取引法 */
  #public_page #commercial_transaction_page th{
    width: 33%;
  }
  /* プライバシーポリシー */
  #public_page #privacy_policy_page .introduction{
    width: 100%;
    text-align: left;
  }
  /* お問い合わせ */
  #public_page #contact_page table th,
  #public_page #contact_page table td{
    display: block;
    width: 98%;
    text-align: left;
  }
  #public_page #contact_page table td .radio_btn_label{
    display: inline-block;
    margin: 10px 15px;
  }
  #public_page #contact_page table th{
    padding: 10px 0 10px 5px;
  }
  #public_page #contact_page table td{
    padding: 20px 0 20px 5px;
  }
  #public_page #contact_page table th span br{
    display: none;
  }
  #public_page #contact_page table td .family_name_area span,
  #public_page #contact_page table td .first_name_area span{
    text-align: left;
  }
  #public_page #contact_page table td .family_name_area input,
  #public_page #contact_page table td .first_name_area input{
    width: 100%;
  }
  #public_page #contact_page table td span{
    display: block;
    margin: 15px 0 0;
  }
  #public_page #contact_page table td textarea{
    width: 96%;
  }
  /* 商品一覧 */
  #public_page .list_type_1 .item_card{
    width: calc(100% / 3 - 10px);
  }
  #public_page .list_type_1 .item_card .thumbnail_area{
    height: calc(70vh / 3 - 10px);
  }
  /* 商品詳細 */
  #public_page #item_detail_page .item_info_area{
    display: block;
  }
  /* お気に入り一覧 */
  #public_page #item_list_page .item_card .btn_area button{
    width: 98%;
  }
  #public_page .list_type_1 .nostock .thumbnail_area::after{
    padding-top: 45%;
  }
  /* 新規会員登録・会員情報変更 */
  #public_page .form_type_1 table th,
  #public_page .form_type_1 table td{
    display: block;
    width: 98%;
  }
  #public_page .form_type_1 table th{
    padding: 4px 0 4px 5px;
    text-align: left;
  }
  #public_page .form_type_1 table td{
    padding: 25px 0 25px 5px;
  }
  #public_page .form_type_1 table td .family_name_area span,
  #public_page .form_type_1 table td .first_name_area span{
    text-align: left;
  }
  #public_page .form_type_1 table td .family_name_area input,
  #public_page .form_type_1 table td .first_name_area input{
    width: 100%;
  }
  #public_page .form_type_1 table td input[name='password'],
  #public_page .form_type_1 table td input[name='re_password'],
  #public_page .form_type_1 table td input[name='mail_address'],
  #public_page .form_type_1 table td input[name='re_mail_address'],
  #public_page .form_type_1 table td input[name='birthday'],
  #public_page .form_type_1 table td input[name='address']{
    width: 90%;
  }
  #public_page .form_type_2 form dt span.required{
    display: inline-block;
  }
  #public_page .form_type_1 table td .family_name_area input,
  #public_page .form_type_1 table td .first_name_area input{
    width: calc((100% - 0.8em) - 10px);
  }
  #public_page .form_type_1 table span{
    display: inline-block;
  }
  #public_page .form_type_1 table span br{
    display: none;
  }
  #public_page #member_edit_page .form_type_1 td button{
    width: 98%;
  }
  /* お届けリスト */
  #public_page .search_add_area{
    display: block;
  }
  #public_page .search_add_area .right_content{
    margin: 20px auto;
  }
  /* 買い物カゴ */
  #public_page .list_type_2 th.item_name_th{
    width: 65%;
  }
  #public_page .list_type_2 th.delete_btn_th{
    width: 60px;
  }
  /* 買い物カゴ～お届け先情報入力 */
  #public_page .list_type_3 .order_card .delete_btn{
    margin-right: 0;
  }
  #public_page .list_type_3 .order_card .post_info{
    display: block;
  }
  #public_page .list_type_3 .order_card h3{
    width: 100%;
  }
  /* 買い物カゴ～入力内容の確認 */
  #public_page .order_card_box .order_card dd span.item_name,
  #public_page .order_card_box .order_card dd span.standard{
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
  }
  #public_page .order_card_box .order_card dd span.number,
  #public_page .order_card_box .order_card dd span.price{
    width: calc(100% / 2 - 30px);
    display: inline-block;
  }
  /* 購入履歴 */
  #public_page #order_history_page .list_area td:nth-child(2){
    width: 45%;
  }
  #public_page #order_history_page .list_area div{
    margin: 10px 15px;
    display: block;
  }
  #public_page #order_history_page .list_area .thumbnail_area{
    width: 70%;
  }
  #public_page #order_history_page .list_area .itemName_area{
    width: 80%;
  }
}
@media screen and (max-width: 580px){
  /* 商品一覧 */
  #public_page .list_type_1 .item_card{
    width: calc(100% / 2 - 10px);
    max-width: calc(100% / 2 - 10px);
  }
  #public_page .list_type_1 .item_card .thumbnail_area{
    height: calc(45vh / 2 - 10px);
  }
  /* 商品詳細 */
  #public_page #item_detail_page .item_info_area .right_content{
    width: 100%;
  }
  /* お気に入り一覧 */
  #public_page .btn_area{
    margin-top: 65px;
  }
  #public_page .list_type_1 .nostock .thumbnail_area::after{
    padding-top: 35%;
  }
  /* 買い物カゴ～ */
  #public_page .order_info_link_btn,
  #public_page .order_post_info_link_btn{
    display: block;
    margin: 15px auto !important;
  }
  #public_page .list_type_2 td.item_image_name_box div{
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
  /* 買い物カゴ～お届け情報追加 */
  #public_page .add_address_btn_area{
    display: flex;
    justify-content: space-around;
  }
  #public_page .add_address_btn_area button{
    padding: 15px 10px;
    margin: 0 auto;
  }
  /* 買い物カゴ～お届け先情報入力 */
  #public_page .list_type_3 .order_card form .item_name,
  #public_page .list_type_3 .order_card form .item_standard{
    width: 100%;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 500px){
  /* 一般ぺージ(共通) */
  #public_page #temp_1col h1,
  #public_page #temp_2col h1{
    font-size: 22px;
  }
  /* サイドバー(一般) */
  #public_page article .col_2_wrap aside.sidebar{
    padding-bottom: 60px;
  }
  #public_page article .col_2_wrap aside.sidebar ul li{
    display: inline-block;
    width: calc(100% / 2 - 60px);
  }
  /* ヘッダー(一般) */
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(1){
    top: 55px;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(2){
    top: 65px;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(3){
    top: 75px;
  }
  /* フッター(一般) */
  #public_page footer .footer_menu ul li{
    display: block;
  }
  #public_page footer .contact_infomation_area .telnumber_area,
  #public_page footer .contact_infomation_area .mailform_area{
    margin: 0 auto;
  }
  #public_page .btn_type_2{
    padding: 8px 70px;
  }
  #public_page footer .footer_menu ul{
    width: 100%
  }
  #public_page footer .media_area{
    width: 100%;
    text-align: start;
    margin-left: 20px;
  }
  
  /* 一般ぺージ(TOP) */
  #public_page .shop_introduce_area .text_area img{
    float: initial;
    width: 95%;
    margin: 0 auto;
  }
  #public_page .recommend_item_list_area .recommend_item_card,
  #public_page .recommend_item_list_area .recommend_item_card.big_card{
    width: calc(100% / 2 - 10px);
    max-width: calc(100% / 2 - 10px);
  }
  #public_page .recommend_item_list_area .recommend_item_card .thumbnail_area,
  #public_page .recommend_item_list_area .recommend_item_card.big_card .thumbnail_area{
    height: calc(55vh / 2 - 10px);
  }
  #public_page .item_list_area .item_card{
    width: calc(100% / 2 - 10px);
    max-width: calc(100% / 2 - 10px);
  }
  #public_page .item_list_area .item_card .thumbnail_area{
    height: calc(55vh / 2 - 10px);
  }
  /* ご利用について */
  #public_page #temp_1col section h2{
    font-size: 20px;
  }
  /* お知らせ一覧 */
  #public_page #news_list_page .news_card .right_content{
    width: 150px;
    height: 150px;
    margin-top: 15%;
  }
  /* 買い物カゴ */
  #public_page .list_type_2 .scroll_wrap{
    overflow: auto;
  }
  #public_page .list_type_2 .scroll_wrap table{
    width: 500px;
  }
  /* 買い物カゴ～お届け先情報入力 */
  #public_page .order_post_info_content .introduction button{
    margin-left: 0;
    margin-top: 20px;
  }
  /* お届けリスト */
  #public_page .list_area .scroll_wrap{
    overflow: auto;
  }
  #public_page .list_area .scroll_wrap table{
    width: 500px;
  }
  #public_page .list_area .scroll_wrap table th,
  #public_page .list_area .scroll_wrap table td{
    white-space: nowrap;
  } 
  #public_page .sp_txt{
    display: block;
    margin-left: 10px;
  }
}
@media screen and (max-width: 400px){
  /* ヘッダー(一般) */
  #public_page header .right_area{
    display: flex;
    align-items: flex-start;
    right: 0;
  }

  /* お問い合わせ */
  #public_page #contact_page table td .family_name_area,
  #public_page #contact_page table td .first_name_area{
    width: 300px;
  }
  #public_page #contact_page table td .first_name_area{
    margin-left: 0;
  }
  /* お知らせ一覧 */
  #public_page #news_list_page .news_card .right_content{
    width: 100px;
    height: 100px;
    margin-top: 20%;
    margin-left: 10px;
  }
  /* 商品一覧・お気に入り一覧 */
  #public_page .list_type_1 .item_card{
    width: 100%;
    max-width: 100%;
  }
  #public_page .list_type_1 .item_card .thumbnail_area{
    height: calc(60vh / 2 - 10px);
  }
  #public_page .btn_area{
    margin-top: 45px;
  }
  /* 商品詳細 */
  #public_page #item_detail_page .item_info_area .left_content p.name{
    font-size: 20px;
  }
  /* お届けリスト */
  #public_page .search_add_area .left_content input{
    width: 60%;
  }
  /* 買い物カゴ～お届け先情報追加 */
  #public_page .add_address_btn_area button{
    margin: 0 10px;
  }
}
